/* eslint no-unused-vars: 0 */ 
import {
    CREATING_VOICE_PROFILE,
    CREATE_VOICE_PROFILE_SUCCESS,
    CREATE_VOICE_PROFILE_ERROR,
    UPDATING_VOICE_PROFILE,
    UPDATING_VOICE_PROFILE_SUCCESS,
    UPDATING_VOICE_PROFILE_ERROR,
    UPDATING_VOICE_PHOTO,
    UPDATE_VOICE_PHOTO_SUCCESS,
    UPDATE_VOICE_PHOTO_ERROR,
    CREATING_VOICE_OVER,
    CREATE_VOICE_OVER_SUCCESS,
    CREATE_VOICE_OVER_ERROR,
    RESET_VOICE_PROFILE_STATE,
    DELETING_VOICE_PROFILE,
    DELETE_VOICE_PROFILE_SUCCESS,
    DELETE_VOICE_PROFILE_ERROR,
} from 'constants/actionTypes';
import {
    ERROR,
    SAVING,
    DELETING,
    DELETED,
    SUCCESS,
} from 'constants/index';
import { ICreationState, } from 'interfaces/index';

interface IAction {
    type: string,
    payload: any
}

export default (state = {} as ICreationState, action = {} as IAction) => {
    const {
        payload 
    } = action;

    switch (action.type) {
        case UPDATING_VOICE_PROFILE:
        case CREATING_VOICE_PROFILE: {
            return {
                ...state,
                voiceClone: { status: SAVING }
            };
        }
        case UPDATING_VOICE_PROFILE_SUCCESS:
        case CREATE_VOICE_PROFILE_SUCCESS: {
            return {
                ...state,
                voiceClone: {
                    ...payload,
                    status: SUCCESS
                }
            };
        }
        case UPDATING_VOICE_PROFILE_ERROR:
        case CREATE_VOICE_PROFILE_ERROR: {
            return {
                ...state,
                voiceClone: { status: ERROR }
            };
        }
        case UPDATING_VOICE_PHOTO: {
            return {
                ...state,
                voicePhoto: { status: SAVING }
            };
        }
        case UPDATE_VOICE_PHOTO_SUCCESS: {
            return {
                ...state,
                voicePhoto: { status: SUCCESS }
            };
        }
        case UPDATE_VOICE_PHOTO_ERROR: {
            return {
                ...state,
                voicePhoto: { status: ERROR }
            };
        }
        case CREATING_VOICE_OVER: {
            return {
                ...state,
                voiceOver: { status: SAVING }
            };
        }
        case CREATE_VOICE_OVER_SUCCESS: {
            return {
                ...state,
                voiceOver: {
                    ...payload,
                    status: SUCCESS
                }
            };
        }
        case CREATE_VOICE_OVER_ERROR: {
            return {
                ...state,
                voiceOver: { status: ERROR }
            };
        }
        case DELETING_VOICE_PROFILE: {
            return {
                ...state,
                voiceClone: { status: DELETING }
            };
        }
        case DELETE_VOICE_PROFILE_SUCCESS: {
            return {
                ...state,
                voiceClone: { status: DELETED }
            };
        }
        case DELETE_VOICE_PROFILE_ERROR: {
            return {
                ...state,
                voiceClone: { status: ERROR }
            };
        }
        case RESET_VOICE_PROFILE_STATE: {
            return {};
        }
        default:
            return state;
    }
};
