import {
    useEffect, 
    useState 
} from 'react';
import {
    useNavigate,
    useLocation
} from 'react-router-dom';
import HorizontalList from "components/HorizontalList";
import PlaylistCover from'components/PlaylistCover';
import LoadingSkeleton from 'components/TopSongs/LoadingSkeleton';
import { isDesktop } from 'utils/index';
import { createPlaylistData } from 'utils/formatters';

import { SUCCESS } from 'constants/index';

import './PlaylistsScroller.scss';

import {
    IPlanObject,
    IPlaylists
} from "interfaces";

interface IPlaylistsScrollerProps {
    className?: string;
    currentPlaylistData: IPlanObject;
    title?: string;
    playlists: IPlaylists;
    loadPlaylistsStatus: string;
    enableScroll?: boolean;
}

function PlaylistsScroller(props: IPlaylistsScrollerProps) {
    const {
        className = '',
        currentPlaylistData = {},
        title = 'Playlists',
        playlists = {},
        loadPlaylistsStatus = '',
        enableScroll = true,
    } = props;

    const {
        pathname,
        state,
    } = useLocation();

    const [playlistsData, setPlaylistsData] = useState<IPlanObject[]>([]);

    useEffect(()=>{
        // loop each playlist and pick each playlist 1st item to create a 'songlist'
        const playlistsArray = createPlaylistData(playlists);
        setPlaylistsData(playlistsArray);

    }, [playlists]);

    useEffect(()=> {
        let scrollTimer: any;
        // scroll to current active playlist
        if (currentPlaylistData.id) {
            // don't scroll to top eg. on home screen
            if (state && !state.scrollTop) {
                return undefined;
            }
            scrollTimer = setTimeout(()=> {
                const activeElement = document.querySelector('.playlists-scroller .playlist__list--active');
                if (activeElement) {
                    activeElement.scrollIntoView({
                        behavior: 'smooth',
                        block: 'nearest',
                        inline: 'start',
                    });
                }
            }, 300);
        }
        return () => {
            clearTimeout(scrollTimer);
        };
    }, [currentPlaylistData, state, pathname]);

    const navigate = useNavigate();

    // eslint-disable-next-line arrow-body-style
    const getActiveCss = (playlistId: string)=> {
        return currentPlaylistData.id  === playlistId
            ? 'playlist__list-item--active '
            : 'playlist__list-item';
    };

    const handleChangePlaylist = (playlistId: string = '')=>{
        navigate(`/playlist/${playlistId}`, { state: { scrollTop: false } });
    };

    const classNames = `playlists-scroller ${isDesktop ? 'disabled' : ''} ${className}`.trim();

    if (loadPlaylistsStatus !== SUCCESS) {
        return (
            <div className={classNames}>
                <h3>
                    {title}
                </h3>
                <HorizontalList enable={enableScroll}>
                    <LoadingSkeleton />
                </HorizontalList>
            </div>
        );
    }

    return (
        <div className={classNames}>
            <h3>
                {title}
            </h3>
            <HorizontalList enable={enableScroll}>
                {
                    playlistsData.map((playlist: IPlanObject, index: number) => {
                        const {
                            id,
                            thumbnail,
                        } = playlist;

                        const colourIndex = index % 5;
                        
                        return (
                            <li 
                                key={playlist.id}
                                className={`${getActiveCss(id)}`.trim()}
                            >
                                <PlaylistCover
                                    title={playlist.title}
                                    cover={thumbnail}
                                    className={!playlist.thumbnail ? `cover-color_${colourIndex}` : ''}
                                    onClick={()=> handleChangePlaylist(playlist.id)}
                                />
                            </li>
                        );
                    })
                }
            </HorizontalList>
        </div>
    );
}

export default PlaylistsScroller;
