/* eslint-disable jsx-a11y/media-has-caption */
import React, { 
    useRef, 
    useCallback,
    useEffect
} from 'react';
import {
    MUSIC_PAUSE,
    MUSIC_PLAY,
} from 'constants/trackingEvents';
import { eventTracker } from 'utils/tracker';
import { formatTime } from 'utils/formatters';

interface IAudioControl {
    className?: string;
    playlistId: string | undefined;
    musicId: string | undefined;
    duration: number | undefined;
    audioRef: React.RefObject<any>;
    progressBarRef: React.RefObject<any>;
    progressInputRangeRef?: React.RefObject<any>;
    progressTimeRef?: React.RefObject<any>;
    audioSrc: string;
    hidden?: boolean;
    isPlaying: boolean;
    handleNext: ()=> any;
    // eslint-disable-next-line no-unused-vars
    updateView: (arg: any) => any;
    // eslint-disable-next-line no-unused-vars
    updateDuration: (arg: number) => any;
    [key: string]: any
}

function AudioControl(props: IAudioControl) {
    const {
        className = '',
        playlistId = '',
        musicId = '',
        duration = 0,
        audioSrc = '',
        hidden = true,
        isPlaying = false,
        handleNext = ()=>{},
        updateView = () => {},
        updateDuration = () => {},
        audioRef,
        progressBarRef,
        progressTimeRef,
        progressInputRangeRef,
    } = props;

    // reference
    const playAnimationRef: any = useRef(null);

    // callback for requestAnimationFrame to update progressbar style and progress
    const repeat = useCallback(() => {
        if (!audioRef || !audioRef.current) {
            return;
        }
        const { 
            currentTime
        } = audioRef.current;

        // update progress bar style
        if (progressBarRef && progressBarRef.current) {
            progressBarRef.current.style.width = `${(currentTime / duration) * 100}%`;
        }
        // update progress time text
        if (progressTimeRef && progressTimeRef.current) {
            progressTimeRef.current.textContent = formatTime(currentTime);
        }
        // update progress range input value and style
        if (progressInputRangeRef && progressInputRangeRef.current) {
            progressInputRangeRef.current.value = currentTime;
            progressInputRangeRef.current.style.setProperty(
                '--range-progress',
                `${(progressInputRangeRef.current.value / duration) * 100}%`
            );
        }

        playAnimationRef.current = requestAnimationFrame(repeat);
        
    }, [audioRef, duration, progressBarRef, progressTimeRef, progressInputRangeRef]);

    // whenever music change, play or pause
    // trigger 'repeat' requestAnimationFrame
    // this effect calls couple times.
    useEffect(()=>{
        const audioNode = audioRef && audioRef.current;
        if (audioNode) {
            cancelAnimationFrame(playAnimationRef.current);

            if (isPlaying) {
                if(audioNode.getAttribute('src') !== '') {
                    audioRef.current.play();
                    playAnimationRef.current = requestAnimationFrame(repeat);
                }
            } else {
                audioRef.current.pause();
            }
        }
    }, [isPlaying, audioRef, playAnimationRef, repeat]);

    // when isPlaying changed
    useEffect(()=> {
        if (isPlaying) {
            updateView({
                playlistId,
                musicId
            });
            eventTracker(MUSIC_PLAY, { musicId });
        } else {
            eventTracker(MUSIC_PAUSE, { musicId });
        }
    }, [isPlaying, playlistId, musicId]);

    const onLoadedMetadata = () => {
        if (audioRef.current && audioRef.current.duration) {
            const seconds = audioRef.current.duration;
            updateDuration(seconds);
        }
    };

    const classNames= `audio-control ${className}`.trim();

    if (!audioSrc) {
        return null;
    }

    return (
        <div 
            className={classNames}
            hidden={hidden}
        >
            <audio
                src={audioSrc}
                ref={audioRef}
                onLoadedMetadata={onLoadedMetadata}
                onEnded={handleNext}
            />
        </div>
    );
}

export default AudioControl;
