import { Button } from '@mui/material';
import { useNavigate } from "react-router-dom";
import * as tracker from 'utils/tracker';
import { ReactComponent as Ai } from 'assets/icons/icon-ai.svg';
import { CREATE as CREATE_EVENT } from 'constants/trackingEvents';
import {
    CREATE,
    STEP_PREFIX
} from 'constants/routes';

import './CreateButton.scss';

interface ICreateButtonProps {
    [key:string]: any
}

function CreateButton(props: ICreateButtonProps) {
    const {
        className = 'create-button',
        variant = 'contained',
        size = 'large',
        endIcon,
        onClick,
        ...restProps
    } = props;

    const navigate = useNavigate();

    const handleClick = () => {
        tracker.event(CREATE_EVENT);
        navigate(`${CREATE}/${STEP_PREFIX}1`);
    };

    const mergedProps = {
        ...restProps,
        className,
        variant,
        size,
        onClick: typeof onClick === 'function' ? onClick : handleClick
    };

    return (
        <Button 
            {...mergedProps}
            endIcon={<Ai aria-hidden />}
        >
            Create
        </Button>
    );
}

export default CreateButton;
