/* eslint-disable import/prefer-default-export */
import {
    voiceData,
    singerData
} from 'data/voiceData';

import { IPlanObject } from 'interfaces';

export function getArtistDetails(data = {} as IPlanObject) {
    const {
        rapperId = '',
        singerId = '',
        version = '1.0',
    } = data;

    const ret = [];

    const rapper = voiceData.find((item) => item.id === rapperId);

    if (rapper) {
        ret.push(rapper);
    }

    if (version !== '1.0') {
        const singer = singerData.find((item: IPlanObject | undefined) => (item && item.id) === singerId);

        if (singer && rapper && rapper.name) {
            if ((singer as any).name !== rapper.name) {
                ret.push(singer);
            }
        }
    }

    return ret;
}
