import { combineReducers } from 'redux';
import music from 'reducers/music';
import account from 'reducers/account';
import creation from 'reducers/creation';
import voiceProfile from 'reducers/voiceProfile';
import { getInitState } from 'store';
import { RESET_APP } from 'constants/actionTypes';

const allReducers: any = combineReducers({
    music,
    creation,
    voiceProfile,
    account,
    [RESET_APP]: (state = {}) => state,
});

export default function rootReducer(state: any, action: any) {
    let appState = state;
    if (action && action.type === RESET_APP) {
        appState = getInitState();
        appState[RESET_APP] = true;
    }
    return allReducers(appState, action);
}
