import {
    useState,
    useEffect
} from 'react';
import { useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import Menu from '@mui/material/Menu';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import LogoutIcon from '@mui/icons-material/Logout';
import LoginIcon from '@mui/icons-material/Login';
import HomeIcon from '@mui/icons-material/Home';
import Footer from 'components/Footer';
import { drawerMenu } from 'constants/navigation';
import {
    SIGN_IN ,
    HOME
} from 'constants/routes';
import {
    IDrawerMenu, 
    IPlanObject 
} from 'interfaces';
import { 
    isDesktop, 
    isMobile 
} from 'utils';

import './DrawerMenu.scss';

interface IDrawerMenuProps {
    className?: string;
    // eslint-disable-next-line no-unused-vars
    toggleDrawer?: (arg: boolean)=> any;
    isAuthenticated: boolean;
    signOutUser: ()=>any;
}

// let mainMenus: IPlanObject[] = drawerMenu;

const createSubmenuItems = drawerMenu[0].items;



function DrawerMenu(props: IDrawerMenuProps) {
    const {
        className = '',
        toggleDrawer = ()=>{},
        isAuthenticated = false,
        signOutUser = () => {},
    } = props;

    const navigate = useNavigate();

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [mainMenu, setMainMenu] = useState<any[] >([]);

    useEffect(()=>{
        if (isDesktop) {
            const desktopMenu = drawerMenu.map((item)=>({
                title: item.title,
                url: item.url,
            }));
            desktopMenu.unshift({
                title: '',
                url: HOME,
                icon: HomeIcon
            } as any);
            setMainMenu(desktopMenu);
        } else {
            setMainMenu(drawerMenu);
        }
    }, []);

    const isSubmenuOpen = Boolean(anchorEl);

    const handleSubmenuOpen = (event: any) => {
        setAnchorEl(event?.currentTarget);
    };
    const handleSubmenuClose = () => {
        setAnchorEl(null);
    };

    const handleClose = ()=>{
        toggleDrawer(false);
    };

    const handleClick = (url = '')=> {
        if (url) {
            navigate(url);
        }
    };

    const handleLogIn = () => {
        navigate(SIGN_IN);
    };

    const handleLogOff = async()=> {
        signOutUser();
    };

    const classNames = `drawer-menu ${isDesktop ? 'drawer-menu--desktop' : ''} ${className}`.trim();

    const boxProps = isMobile && {
        sx: { width: 250 },
        onClick: handleClose,
    };


    const MenuItems = (menuItems: IPlanObject[])=> menuItems.map((item = {} as IDrawerMenu) => (
        <ListItem 
            key={item.url || item.title}
            disablePadding
        >
            <ListItemButton onClick={(e: any)=>{
                // 'create' - trigger submenu open
                if (item.title === drawerMenu[0].title) {
                    handleSubmenuOpen(e);
                } else {
                    handleClick(item.url);
                }
            }} >
                {item.icon && (
                    <ListItemIcon className="list-item__icon">
                        <item.icon />
                    </ListItemIcon>
                )}
                {item.title && (
                    <ListItemText 
                        className="drawer-menu__item-text" 
                        primary={item.title} 
                    />
                )}
            </ListItemButton>
        </ListItem>
    ));

    return (
        <Box 
            className={classNames}
            role="presentation"
            {...boxProps}
        >
            <List className="drawer-menu__list">
                {mainMenu.map((item = {} as IDrawerMenu) => {
                    if (item.items) {
                        return (
                            <ListItem 
                                key={item.url || item.title}
                                disablePadding
                                className="drawer-menu__list-parent"
                            >
                                <div className="drawer-menu__list-item">
                                    {item.icon && (
                                        <ListItemIcon className="list-item__icon">
                                            <item.icon />
                                        </ListItemIcon>
                                    )}
                                    {item.title && (
                                        <ListItemText 
                                            className="drawer-menu__item-text" 
                                            primary={item.title} 
                                        />
                                    )}
                                </div>
                                <List className="drawer-menu__sub-list">
                                    {MenuItems(item.items)}
                                </List>
                            </ListItem>
                        );
                    }
                    return MenuItems([item]);
                })}
                {isDesktop && (
                    <ListItem disablePadding>
                        {!isAuthenticated && (
                            <ListItemButton onClick={handleLogIn}>
                                <ListItemText 
                                    className="drawer-menu__item-text"
                                    primary="Log in" />
                            </ListItemButton>
                        )}
                        {isAuthenticated && (
                            <ListItemButton onClick={handleLogOff}>
                                <ListItemText 
                                    className="drawer-menu__item-text"
                                    primary="Log off" />
                            </ListItemButton>
                        )}
                    </ListItem>
                )}
            </List>

            {isMobile && (
                <>
                    <List className="drawer-menu__list">
                        <ListItem disablePadding>
                            {!isAuthenticated && (
                                <ListItemButton onClick={handleLogIn}>
                                    <ListItemIcon className="list-item__icon">
                                        <LoginIcon />
                                    </ListItemIcon>
                                    <ListItemText primary="Log in" />
                                </ListItemButton>
                            )}
                            {isAuthenticated && (
                                <ListItemButton onClick={handleLogOff}>
                                    <ListItemIcon className="list-item__icon">
                                        <LogoutIcon />
                                    </ListItemIcon>
                                    <ListItemText primary="Log off" />
                                </ListItemButton>
                            )}
                        </ListItem>
                    </List>
                    <Footer asColumn />
                </>
            )}
            {/* submenu for desktop */ }
            {isDesktop && (
                <Menu
                    id="sub-menu"
                    anchorEl={anchorEl}
                    open={isSubmenuOpen}
                    onClose={handleSubmenuClose}
                    MenuListProps={{ 'aria-labelledby': 'basic-button', }}
                >
                    <List className="drawer-menu__submenu-list">
                        {MenuItems(createSubmenuItems as IPlanObject[])}
                    </List>
                </Menu>
            )}
        </Box>
    );
}

export default DrawerMenu;
