import PersonIcon from '@mui/icons-material/Person';
import LibraryMusicIcon from '@mui/icons-material/LibraryMusic';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MicNoneIcon from '@mui/icons-material/MicNone';
import LyricsIcon from '@mui/icons-material/Lyrics';
// import CardGiftcardIcon from '@mui/icons-material/CardGiftcard';
import { ReactComponent as Ai } from 'assets/icons/icon-ai.svg';
import {
    CREATE,
    MY_SONGX,
    PROFILE,
    VOICE_CLONING,
    SONG_COVER,
} from 'constants/routes';

export const PAGE_TITLES = {
    LOG_IN: 'Log in',
    CREATE: 'Create',
    RAP: 'Rap',
    VOICE_CLONING: 'AI Singer',
    SONG_COVER: 'Song Remix',
    MY_SONGX: 'Manage',
    PROFILE: 'Profile',
    PRIVACY_POLICY: 'Privacy Policy',
    TERMS_CONDITIONS: 'Terms and Conditions',
};

export const drawerMenu = [
    {
        title: PAGE_TITLES.CREATE,
        icon: ExpandMoreIcon,
        items: [
            {
                title: PAGE_TITLES.VOICE_CLONING,
                description: 'Create an AI singer with your voice',
                url: `${CREATE}/${VOICE_CLONING}/step-1`,
                icon: MicNoneIcon,
            },
            {
                title: PAGE_TITLES.SONG_COVER,
                description: 'Sing your favourite songs with your AI singers',
                url: `${CREATE}/${SONG_COVER}`,
                icon: LyricsIcon, 
            },
            {
                title: PAGE_TITLES.RAP,
                description: 'Create a new rap',
                url: `${CREATE}/step-1`,
                icon: Ai,
            }
        ]
    },
    {
        title: PAGE_TITLES.MY_SONGX,
        url: MY_SONGX,
        icon: LibraryMusicIcon
    },
    {
        title: PAGE_TITLES.PROFILE,
        url: PROFILE,
        icon: PersonIcon
    },
    /*
    {
        title: 'Rewards',
        url: REWARDS,
        icon: CardGiftcardIcon
    }
    */
];
