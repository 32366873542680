export const CONTACT_EMAIL = process.env.REACT_APP_CONTACT;
export const SAVING = 'SAVING';
export const DELETING = 'DELETING';
export const DELETED = 'DELETED';
export const SUCCESS = 'SUCCESS';
export const ERROR = 'ERROR';
export const LOADING = 'LOADING';
export const RELOAD = 'RELOAD';
export const UPDATING = 'UPDATING';
export const SUBMITTING = 'SUBMITTING';
export const SUBMITTED = 'SUBMITTED';
export const CONVERTING = 'CONVERTING';
export const CONVERTED = 'CONVERTED';

export const SHARE_CONFIG = {
    title: 'Songx',
    text: 'Make your own songx',
    url: 'https://songx.ai',
    files: undefined,
};

export const REQUIRED_FIELD = 'This field is required';
export const EMAIL_ERROR = 'Email is invalid';

export const REFRESH_PLAYLIST_TIME = 4 * 60 * 1000;

export const SONGX_COOKIE = 'songx';
export const MAX_CREATION_COUNT = 7;

export const PLAYLIST_NAMES = {
    TOP_SONGS: 'topSongs',
    DEFAULT_PLAYLIST: 'playlist',
};

export const ID_PROVIDERS = {
    GOOGLE: 'GOOGLE',
    FACEBOOK: 'FACEBOOK',
};

export const DEFAULT_PLAYLIST_TITLE = 'Latest';
export const MY_SONGS_TITLE = 'My songs';

export const VIEW_TOGGLE = {
    GALLERY: 'gallery',
    LIST: 'list'
};

export const OPERATIONS = {
    CREATE: 'CREATE',
    DELETE: 'DELETE',
    EDIT: 'EDIT',
    ADD: 'ADD',
    REMOVE: 'REMOVE',
};

export const ACTIVE = 'active';

export const SKIP_VALIDATION = 'NA';
