/* eslint-disable import/no-extraneous-dependencies */
import axios from 'axios';
import { onAuthStateChanged } from "firebase/auth";
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { store } from 'store';
import CssBaseline from '@mui/material/CssBaseline';
import {
    createTheme, 
    ThemeProvider 
} from '@mui/material/styles';
import themeOptions from 'Theme';
import App from 'containers/App';
import ErrorBoundary from 'components/ErrorBoundary';
import ErrorPage from 'pages/Error';
import {
    firebaseAuth,
    getIdToken
} from 'utils/firebase'; // init firebase by importing this file
import { ISongxNamespace } from 'interfaces';
import { initMobileCheck } from 'utils/index';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
// import reportWebVitals from './reportWebVitals';

// global style
import 'styles/index.scss';


// songx global namespace
declare global {
    // eslint-disable-next-line no-unused-vars
    interface Window {
        SONGX: ISongxNamespace;
    }
}

window.SONGX = window.SONGX || {};

initMobileCheck();

// axios intercetor
// normalist API signature and status code
axios.interceptors.response.use(
    (response) => {
        if (response.data && response.data.statusCode && !(response.data.statusCode >= 200 && response.data.statusCode < 300)) {
            throw new Error(`Error status: ${response.data.statusCode}`);
        }

        return response.data.data ? response.data : response;
    },
    (error) => Promise.reject(error)
);

let axiosReqInterceptor: any;

// since we listening to onAuthStateChanged. This callback will trigger whenever user signin or signout
// however we just need to render the app once.
let isAppRendered = false;

const theme = createTheme(themeOptions);

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

// render App once
const renderApp = ()=> {
    if (isAppRendered) {
        return;
    }

    root.render(
        <ErrorBoundary fallback={ErrorPage}>
            <BrowserRouter>
                <Provider store={store}>
                    <CssBaseline />
                    <ThemeProvider theme={theme}>
                        <App />
                    </ThemeProvider>
                </Provider>
            </BrowserRouter>
        </ErrorBoundary>
    );

    isAppRendered = true;
};


const removeAxiosReqIntercetor = ()=> {
// remove interceptor if any
    if (axiosReqInterceptor) {
        axios.interceptors.request.eject(axiosReqInterceptor);
    }
};

// setup Authorization header logic
// listen to onAuthStateChanged. once user signedin, Google keeps browser session on their side
// when user reload the page, we should still able to identify the user by uid
// client side then store only minimum info in sessionStorage and retrive by uid
// therefore we never need to store idToken

onAuthStateChanged(firebaseAuth, async (user) => {
    try {
        if (!user) {
            removeAxiosReqIntercetor();
            renderApp();
            return;
        }
            
        // User is signed in, see docs for a list of available properties
        // https://firebase.google.com/docs/reference/js/auth.user
        
        const idToken = await getIdToken();
        
        if (idToken) {
            // remove interceptor if any
            removeAxiosReqIntercetor();
                    
            // add authorization header to all songx.ai API calls
            axiosReqInterceptor = axios.interceptors.request.use((config) => {
                const newConfig = { ...config };
                const {
                    url = '',
                } = newConfig;
        
                // send Authorization header if api.songx.ai call 
                if ((/^https:\/\/api.songx.ai+/).test(url)) {
                    newConfig.headers = {
                        ...newConfig.headers,
                        Authorization: `Bearer ${idToken}`
                    } as any;
                }
                return newConfig;
            }, (error) => 
            // Do something with request error
                Promise.reject(error)
            );
        }
        renderApp();
    } catch(err) {
        removeAxiosReqIntercetor();
        renderApp();
    }
});

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
// serviceWorkerRegistration.unregister();
// passing config object with onUpdate callback to force clear cache
if ('serviceWorker' in navigator) {
    serviceWorkerRegistration.register({
        onUpdate: ((registration) => {
            registration?.waiting?.postMessage({ type: 'SKIP_WAITING' });
            // eslint-disable-next-line no-console
            console.log('service worker update');
        })
    });
    // reload page after delete all old cache on active - trigger from service-worker.ts
    navigator.serviceWorker.addEventListener('message', (event) => {
        if (event.data?.type === 'RELOAD_PAGE') {
            window.location.reload();
        }
    });
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();

document.addEventListener('DOMContentLoaded', () => {
    // set csrf token to all header
    const csrfTokenTag =  document.querySelector('meta[name="csrf-token"]');
    if (csrfTokenTag) {
        // axios.defaults.headers.common['X-CSRF-TOKEN'] = csrfTokenTag.getAttribute('content');
    }
});