/* eslint-disable dot-notation */
/* eslint-disable no-underscore-dangle */
import axios from 'axios';
import { 
    signInWithProvider,
    signOutAsync
} from 'utils/firebase';
import {
    convertUserData,
    convertUserProfileUpdate,
    convertUpdatedUserProfileData,
    convertUserPlaylistsData
} from 'dataConverter';
import {
    LOGIN_PENDING,
    LOGIN_SUCCESS,
    LOGIN_ERROR,
    LOGOFF_SUCCESS,
    LOGOFF_ERROR,
    UPDATING_PROFILE,
    UPDATE_PROFILE_SUCCESS,
    UPDATE_PROFILE_ERROR,
    LOADING_USERS_PLAYLISTS,
    LOADING_USERS_PLAYLISTS_SUCCESS,
    LOADING_USERS_PLAYLISTS_ERROR
} from 'constants/actionTypes';
import {
    USER_PROFILE_API,
    USER_PROFILE_UPDATE_API,
    GET_PLAYLISTS_API,
    AXIOS_NO_CACHE_HEADERS,
    GET_VOICE_PROFILE_LIST_API,
} from 'constants/api';
import { IPlanObject } from 'interfaces';

const getSongxUserData = () => {
    let headers = AXIOS_NO_CACHE_HEADERS;

    // overwrite if flag to sync
    if (window.SONGX.shouldSyncProfile) {
        headers = AXIOS_NO_CACHE_HEADERS;
    }

    return axios({
        method: 'get',
        url: USER_PROFILE_API,
        headers
    });
};

const getUserPlaylistsData = (opt: IPlanObject = {}) => {
    let headers = opt && opt.noCache
        ? AXIOS_NO_CACHE_HEADERS
        : {};

    // overwrite if flag to sync
    if (window.SONGX.shouldSyncUsersPlaylist) {
        headers = AXIOS_NO_CACHE_HEADERS;
    }

    return axios({
        method: 'get',
        url: GET_PLAYLISTS_API,
        headers
    });
};

export const signInWithIdProvider = (provider: string) => async (dispatch: any) => {
    try {
        dispatch({ type: LOGIN_PENDING });

        const response = await signInWithProvider(provider);
        const songxUser = await getSongxUserData();

        const providerUserData = response?.user || {};
        const songxUserData = songxUser?.data || {};

        const userData = convertUserData(songxUserData, providerUserData);

        dispatch({ 
            type: LOGIN_SUCCESS,
            payload: userData
        });
    } catch(err) {
        // console.log('err: ', err);
        dispatch({ 
            type: LOGIN_ERROR,
            payload: LOGIN_ERROR
        });
    }
};

// If user already loggedin with Firebase, but not yet getting Songx profile.
// eg when user reload the page
export const getSongxUserProfile  = (providerData = {} as IPlanObject) => async (dispatch: any) => {
    try {
        dispatch({ type: LOGIN_PENDING });

        const [songxUser, usersPlaylists] = await Promise.all([
            getSongxUserData(),
            getUserPlaylistsData(),
        ]);

        const songxUserData = songxUser?.data || {};
        const userData = convertUserData(songxUserData, providerData);

        const userPlaylistsData = convertUserPlaylistsData(usersPlaylists?.data || {});

        dispatch({ 
            type: LOGIN_SUCCESS,
            payload: userData
        });

        dispatch({ 
            type: LOADING_USERS_PLAYLISTS_SUCCESS,
            payload: userPlaylistsData
        });

        window.SONGX.shouldSyncUsersPlaylist = false;
        window.SONGX.shouldSyncProfile = false;
    } catch(err) {
        dispatch({ 
            type: LOGIN_SUCCESS,
            payload: LOGIN_ERROR
        });
    }
};

export const signOutUser = () => async (dispatch: any) => {
    try {
        await signOutAsync();
        dispatch({ type: LOGOFF_SUCCESS });
        window.location.reload();
    } catch (err) {
        dispatch({ type: LOGOFF_ERROR });
    }
};

export const updateAccountProfile = (profileData = {} as IPlanObject) => (dispatch: any) => {
    dispatch({ type: UPDATING_PROFILE });

    const profileDataDto = convertUserProfileUpdate(profileData);

    return axios({
        method: 'post',
        url: USER_PROFILE_UPDATE_API,
        data: profileDataDto
    }).then((response) => {
        const {
            data,
        } = response;

        const updatedUserProfileData = convertUpdatedUserProfileData(data);

        dispatch({
            type: UPDATE_PROFILE_SUCCESS,
            payload: updatedUserProfileData,
        });

        window.SONGX.shouldSyncProfile = true;

    }).catch((error: any) => {
        dispatch({ type: UPDATE_PROFILE_ERROR });

        throw error;
    });
};

export const getUserPlaylists = (opt: IPlanObject) => async (dispatch: any) => {
    try {
        dispatch({ type: LOADING_USERS_PLAYLISTS });

        const usersPlaylists = await getUserPlaylistsData(opt);
        const userPlaylistsData = convertUserPlaylistsData(usersPlaylists?.data || {});

        dispatch({ 
            type: LOADING_USERS_PLAYLISTS_SUCCESS,
            payload: userPlaylistsData
        });
    } catch(err) {
        dispatch({ 
            type: LOADING_USERS_PLAYLISTS_ERROR,
            payload: err
        });
    }
};

export const getVoiceProfileList = (opt: IPlanObject = {}) => {
    let headers = opt && opt.noCache
        ? AXIOS_NO_CACHE_HEADERS
        : {};

    if (window.SONGX.shouldSyncProfile) {
        headers = AXIOS_NO_CACHE_HEADERS;
    }

    return axios({
        method: 'get',
        url: GET_VOICE_PROFILE_LIST_API,
        headers
    });
};