import { useNavigate } from 'react-router-dom';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import { drawerMenu } from 'constants/navigation';
import { IPlanObject } from 'interfaces';

import './CreatList.scss';

interface ICreatListProps {
    className?: string;
    [key: string]: any;
}

const createMenu = drawerMenu[0]?.items;

function CreatList(props: ICreatListProps) {
    const {
        className = '',
        ...restProps
    } = props;

    const navigate = useNavigate();

    const handleClick = (url = '')=> {
        if (url) {
            navigate(url);
        }
    };

    const menuItems = (itemlist: IPlanObject[] = [])=> itemlist?.map((item = {} as IPlanObject)=>{
        const key = item.url || item.title;
        return (
            <ListItem 
                key={key}
                disablePadding
            >
                <ListItemButton onClick={(e: any) => {
                    e.preventDefault();
                    handleClick(item.url);
                }} >
                    {item.icon && (
                        <ListItemIcon className="list-item__icon">
                            <item.icon />
                        </ListItemIcon>
                    )}
                    {item.title && (
                        <ListItemText 
                            className="drawer-menu__item-text" 
                            primary={item.title}
                            secondary={item.description}
                        />
                    )}
                </ListItemButton>
            </ListItem>
        );
    });

    const classNames = `creat-list ${className}`.trim();

    return (
        <List 
            className={classNames}
            {...restProps}
        >
            {menuItems(createMenu)}
        </List>
    );
}

export default CreatList;
