import { IPlanObject } from "interfaces";

const SI_SYMBOL = ['', 'k', 'M', 'G', 'T', 'P', 'E'];

/**
 * 
 * @param number 
 * @description convert number into string like '1.6m', 12k'
 * ref: https://stackoverflow.com/questions/9461621/format-a-number-as-2-5k-if-a-thousand-or-more-otherwise-900
 * @returns 
 */
export function abbreviateNumber(number: number){
    // what tier? (determines SI symbol)
    // eslint-disable-next-line no-bitwise
    const tier = Math.log10(Math.abs(number)) / 3 | 0;

    // if zero, we don't need a suffix
    if(tier === 0) {
        return number;
    }

    // get suffix and determine scale
    const suffix = SI_SYMBOL[tier];
    const scale = 10 ** (tier * 3);

    // scale the number
    const scaled = number / scale;

    // format number and add suffix
    return scaled.toFixed(1) + suffix;
}

export function formatTime (time: number | never) {
    if (typeof time === 'number') {
        const minutes = Math.floor(time / 60);
        const formatMinutes = minutes < 10
            ? `0${minutes}`
            : `${minutes}`;
        const seconds = Math.floor(time % 60);
        const formatSeconds = seconds < 10 
            ? `0${seconds}` 
            : `${seconds}`;

        return `${formatMinutes}:${formatSeconds}`;
    }
    return '00:00';
};

export function toNumber(arg: any) {
    const ret = Number(arg);
    return (Number.isNaN(ret))
        ? 0
        : ret;
}

export function toStanceCase(stg = '') {
    const ret = typeof stg === 'string'
        ? `${stg}`.trim()
        : '';

    return ret.charAt(0).toUpperCase() + ret.slice(1);
}


export function createPlaylistData(playlists = {} as IPlanObject) {
    // loop each playlist and pick each playlist 1st item to create a 'songlist'
    const playlistsArray = [];
    // eslint-disable-next-line no-restricted-syntax
    for (const [, playlist] of Object.entries(playlists)) {
        if (playlist && playlist.musics) {
            playlistsArray.push({
                id: playlist.id || '',
                title: playlist.title || '',
                thumbnail: playlist.musics[0]?.thumbnail || '',
                musicId: playlist.musics[0]?.musicId || '',
            });
        }
    }

    return playlistsArray;
}