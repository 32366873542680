import { connect } from 'react-redux';
import {
    getPlaylist,
    changeMusic,
    setPlayerOpen,
    getPlaylistsMusics,
    setSlideDialogOpen,
    clearPlaylistCache,
} from 'actions/music';
import {
    getArtists,
    getMusicSelections,
} from 'actions/creation';
import Home from 'pages/Home';

import {
    IPlanObject, 
    IRootState,
    IChangeMusic,
    ISlideDialog
} from 'interfaces/index';

const mapStateToProps = (state: IRootState) => ({ 
    music: state.music,
    isAuthenticated: !!state.account?.authenticated,
    userPlaylists: state.account?.playlists || {},
});

const mapDispatchToProps = (dispatch: any) => ({ 
    getPlaylist: (opt: IPlanObject) => dispatch(getPlaylist(opt)),
    getArtists: () => dispatch(getArtists()), 
    getMusicSelections: () => dispatch(getMusicSelections()), 
    changeMusic: (data: IChangeMusic) => dispatch(changeMusic(data)),
    setPlayerOpen: (isOpen: boolean) => dispatch(setPlayerOpen(isOpen)),
    getPlaylistsMusics: (playlists: IPlanObject) => dispatch(getPlaylistsMusics(playlists)),
    setSlideDialogOpen: (data: ISlideDialog) => dispatch(setSlideDialogOpen(data)),
    clearPlaylistCache: (opt: IPlanObject) => dispatch(clearPlaylistCache(opt)),
});

const connector = connect(mapStateToProps, mapDispatchToProps);
// @ts-ignore
export default connector(Home);
