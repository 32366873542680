import axios from 'axios';
import {
    LOADING_ARTISTS,
    LOADING_ARTISTS_SUCCESS,
    LOADING_ARTISTS_ERROR,
    LOADING_MUSIC_SELECTION,
    LOADING_MUSIC_SELECTION_SUCCESS,
    LOADING_MUSIC_SELECTION_ERROR,
    CREATING_MUSIC,
    CREATE_MUSIC_SUCCESS,
    CREATE_MUSIC_ERROR,
    UPDATE_CREATE_MUSIC_STATUS,
    CONVERTING_MIX_MUSIC_URL,
    CONVERT_MIX_MUSIC_URL_SUCCESS,
    CONVERT_MIX_MUSIC_URL_ERROR,
    CREATING_PLAYLIST,
    CREATE_PLAYLIST_SUCCESS,
    CREATE_PLAYLIST_ERROR,
    RESET_CREATE_PLAYLIST,
    DELETING_PLAYLIST,
    DELETE_PLAYLIST_SUCCESS,
    DELETE_PLAYLIST_ERROR,
    UPDATING_PLAYLIST,
    UPDATE_PLAYLIST_SUCCESS,
    UPDATE_PLAYLIST_ERROR,
} from 'constants/actionTypes';
import { 
    convertArtistsData,
    convertMusicSelectionData,
    convertCreateMusicRequest,
    convertCreateMusicReponse,
    convertCreatePlaylistData,
    convertUpdatePlaylistData,
} from 'dataConverter';
import { 
    ARTISTS_API,
    MUSIC_SELECTION_API,
    MUSIC_CREATE_API,
    MUSIC_CREATE_PLAYLIST,
    PLAYLIST_API,
    PLAYLIST_UPDATE_API,
} from 'constants/api';
import { updateCreationRecord } from 'utils/index';
import { getFileUrl } from 'utils/firebase';
import { IPlanObject, } from 'interfaces';


export const getArtists = () => (dispatch: any) => {
    dispatch({ type: LOADING_ARTISTS });

    return axios({
        method: 'get',
        url: ARTISTS_API,
    }).then((response) => {
        const {
            data,
        } = response;

        const artistsData = convertArtistsData(data);

        dispatch({
            type: LOADING_ARTISTS_SUCCESS,
            payload: artistsData,
        });

        return artistsData;
    }).catch((error: any) => {
        dispatch({ type: LOADING_ARTISTS_ERROR });

        return error;
    });
};

export const getMusicSelections = () => (dispatch: any) => {
    dispatch({ type: LOADING_MUSIC_SELECTION });

    return axios({
        method: 'get',
        url: MUSIC_SELECTION_API,
    }).then((response) => {
        const {
            data,
        } = response;

        const musicSelectionData = convertMusicSelectionData(data);

        dispatch({
            type: LOADING_MUSIC_SELECTION_SUCCESS,
            payload: musicSelectionData,
        });

        return musicSelectionData;
    }).catch((error: any) => {
        dispatch({ type: LOADING_MUSIC_SELECTION_ERROR });

        return error;
    });
};

export const createMusic = (formValues = {} as IPlanObject) => (dispatch: any) => {
    dispatch({ type: CREATING_MUSIC });

    const reqData = convertCreateMusicRequest(formValues);

    return axios({
        method: 'post',
        url: `${MUSIC_CREATE_API}`,
        data: reqData
    }).then((response) => {
        const {
            data,
        } = response;

        const result = convertCreateMusicReponse({
            ...data,
            ...reqData
        });

        dispatch({
            type: CREATE_MUSIC_SUCCESS,
            payload: result,
        });

        updateCreationRecord(result.musicId);

        return result;
    }).catch((error: any) => {
        dispatch({ type: CREATE_MUSIC_ERROR });

        return error;
    });
};

export const updateCreateMusicStatus = (status: string = '') => (dispatch: any) => {
    dispatch({ 
        type: UPDATE_CREATE_MUSIC_STATUS,
        payload: status
    });
};

export const getMusicUrl = (data = {} as IPlanObject) => (dispatch: any) => {
    const {
        filePath = '',
    } = data;

    dispatch({ type: CONVERTING_MIX_MUSIC_URL });

    return getFileUrl(filePath)
        .then((value)=> {
            dispatch({
                type: CONVERT_MIX_MUSIC_URL_SUCCESS,
                payload: {
                    ...data,
                    filePath: value
                },
            });

            return value;
        })
        .catch(() => {
            dispatch({ type: CONVERT_MIX_MUSIC_URL_ERROR });
        });
};

export const createPlaylist = (opt = {} as IPlanObject)=> async (dispatch: any) => {
    try {
        dispatch({ type: CREATING_PLAYLIST });

        const createPlaylistData = convertCreatePlaylistData(opt);

        const result = await axios({
            method: 'post',
            url: MUSIC_CREATE_PLAYLIST,
            data: createPlaylistData
        });

        dispatch({ 
            type: CREATE_PLAYLIST_SUCCESS,
            payload: result
        });

        window.SONGX.shouldSyncUsersPlaylist = true;
        window.SONGX.shouldSyncPlaylist = true;

        return result;
    } catch(err: any) {
        dispatch({ type: CREATE_PLAYLIST_ERROR });
        throw err;
    }
};

export const updatePlaylist = (opt = {} as IPlanObject)=> async (dispatch: any) => {
    try {
        const {
            playlistId = '',
            values = {}, 
        } = opt;

        dispatch({ type: UPDATING_PLAYLIST });

        const createPlaylistData = convertUpdatePlaylistData(values);

        const result = await axios({
            method: 'post',
            url: `${PLAYLIST_UPDATE_API}/${playlistId}`,
            data: createPlaylistData
        });

        dispatch({ type: UPDATE_PLAYLIST_SUCCESS, });

        window.SONGX.shouldSyncUsersPlaylist = true;
        window.SONGX.shouldSyncPlaylist = true;

        return result;
    } catch(err: any) {
        dispatch({ type: UPDATE_PLAYLIST_ERROR });
        throw err;
    }
};

export const deletePlaylist = (playlistId = '')=> async (dispatch: any) => {
    try {
        dispatch({ type: DELETING_PLAYLIST });

        const result = await axios({
            method: 'delete',
            url: `${PLAYLIST_API}/${playlistId}`,
        });

        dispatch({ 
            type: DELETE_PLAYLIST_SUCCESS, 
            payload: playlistId
        });

        window.SONGX.shouldSyncUsersPlaylist = true;
        window.SONGX.shouldSyncPlaylist = true;

        return result;
    } catch(err: any) {
        dispatch({ type: DELETE_PLAYLIST_ERROR });
        throw err;
    }
};

export const resetCreatePlaylist = ()=> (dispatch: any) => {
    dispatch({ type: RESET_CREATE_PLAYLIST });
};
