import React, { 
    useEffect,
    useState,
    Suspense,
    useCallback,
} from 'react';
import { 
    useParams,
    useLocation,
    useNavigate,
} from 'react-router-dom';
import { Container } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { trackWindowScroll } from 'react-lazy-load-image-component';
import MiniPlayer from 'containers/MiniPlayer';
import SongList from 'containers/SongList';
import CreateButton from 'components/CreateButton';
import ShareButton from 'containers/ShareButton';
import {
    deviceInfo,
    updateMetaTag, 
    isDesktop,
    isMobile,
} from 'utils/index';
import PlaylistsScroller from 'containers/PlaylistsScroller';
import PlaylistToggle from 'containers/PlaylistToggle';
import SlideDialog from 'components/Modal/SlideDialog';
import AddSong from 'containers/AddSong';
import CreatList from 'pages/Home/CreatList';
import { 
    VIEW_TOGGLE,
    REFRESH_PLAYLIST_TIME,
    SUCCESS,
    ERROR,
} from 'constants/index';
import { DEFAULT_PLAYLIST_ID } from 'constants/api';

import './Home.scss';

import {
    IPendingTrack, 
    IPlaylists,
    IChangeMusic,
    ITrack,
    ICurrentMusic,
    IMusicState,
    IAccountPlaylistsState,
    IPlanObject,
    ISlideDialog,
} from 'interfaces';

// @ts-ignore
const SongListTrackedScroll = trackWindowScroll(SongList);

interface IHomeProps {
    music: IMusicState;
    // eslint-disable-next-line no-unused-vars
    getPlaylist: (arg: any) => any;
    // eslint-disable-next-line no-unused-vars
    changeMusic: (arg: IChangeMusic) => any;
    // eslint-disable-next-line no-unused-vars
    setPlayerOpen: (arg: boolean) => any;
    // eslint-disable-next-line no-unused-vars
    getPlaylistsMusics: (arg: IPlanObject) => any;
    createMusicQueue?: IPendingTrack[];
    isAuthenticated?: boolean;
    userPlaylists?: IAccountPlaylistsState;
    // eslint-disable-next-line no-unused-vars
    setSlideDialogOpen: (arg: ISlideDialog) => any;
    // eslint-disable-next-line no-unused-vars
    clearPlaylistCache:  (arg?: IPlanObject) => any;
    [key: string]: any;
}

const ShareBar = deviceInfo.isDesktop ? React.lazy(() => import('containers/ShareBar')) : React.Fragment;

const noop = () => {};

// The player will play the first music from this playlist.
// set 'Top 10' or 'Latest' playlists id
const defaultPlaylistId = DEFAULT_PLAYLIST_ID;

let refreshPlaylistCount = 0;

function Home(props: IHomeProps) {
    const {
        music = {} as IMusicState,
        getPlaylist = noop,
        changeMusic = noop,
        setPlayerOpen = noop,
        getPlaylistsMusics =noop,
        isAuthenticated = false,
        userPlaylists = {} as IAccountPlaylistsState,
        createMusicQueue,
        setSlideDialogOpen = noop,
        clearPlaylistCache = noop,
    } = props;

    const {
        currentMusic = {} as ICurrentMusic,
        playlists = {} as IPlaylists,
        loadPlaylistsStatus = '',
        slideDialog = {},
    } = music;

    const location = useLocation();
    const navigate = useNavigate();

    // get url params
    const { 
        playlistId = '', 
        musicId,
    } = useParams();

    const [isDefaultPlaylistsLoaded, setIsDefaultPlaylistsLoaded] = useState(false);
    const [isDefaultMusicSet, setDefaultMusicSet] = useState(false);
    const [isCreateDialogOpen, setIsCreateDialogOpen] = useState(false);
    const [playlistMode, setPlaylistMode] = useState(VIEW_TOGGLE.GALLERY);

    const getAllPlaylists = useCallback(async ()=>{
        // playslists IDs for default view.
        const allPlaylists = [
            DEFAULT_PLAYLIST_ID,
        ];
        // eslint-disable-next-line no-console
        console.log('getPlaylist call');
        // 1. loop allPlaylists to create array of promises
        // 2. parallel call using Promise.allSettled
        const playlistPromises = allPlaylists.map((id = '') => getPlaylist({ playlistId: id }));
        // eslint-disable-next-line no-unused-vars
        const playlistResults = await Promise.allSettled(playlistPromises);

        setIsDefaultPlaylistsLoaded(true);
    }, [setIsDefaultPlaylistsLoaded]);

    // onMount - get 'top 10' and 'default' playlists data
    useEffect(() => {
        getAllPlaylists();
    }, []);

    // get playlist and song when playlistId or musicId present in url
    useEffect(() => {
        const changeDefaultMusic = () => {
            // 3. if there is 'musicId' from URL ('/playlistId/musicId')
            // change music by finding match musicId in loaded playlists
            const currentPlaylistId = playlists[playlistId] 
                ?  playlists[playlistId].id 
                : defaultPlaylistId;

            // use default playlist first
            let targetPlaylist = playlists[defaultPlaylistId || ''];

            // check if default playlist loaded
            if (targetPlaylist && targetPlaylist.status === ERROR) {
                targetPlaylist = null;
            }

            // verify playlist provided from url 'playlistId' has music and use as targetPlaylist
            // because it could be an empty playlist
            if (playlists[currentPlaylistId] && 
                playlists[currentPlaylistId].musics && 
                playlists[currentPlaylistId]?.musics[0]
            ) {
                targetPlaylist = playlists[currentPlaylistId];
            }
            
            let currentMusicId = musicId;
            let currentMusicIndex = 0;

            // find currentMusicIndex - when musicId present in url
            if (currentMusicId && targetPlaylist) {
                if (Array.isArray(targetPlaylist.musics)) {
                    const musicIndex = targetPlaylist.musics.findIndex((m: ITrack) => m.musicId === currentMusicId);
                    currentMusicIndex = musicIndex >= 0
                        ? musicIndex
                        : undefined;
                }
            } else {
                // when no currentMusicId but may has targetPlaylist.musics
                // set currentMusicId as first musicId from matched playlist
                currentMusicId = targetPlaylist?.musics[0]
                    ? targetPlaylist.musics[0]?.musicId
                    : '';
            }

            // finally call changeMusic logic
            if (targetPlaylist && targetPlaylist.musics?.length) {
                // on first load, when musicId in url but couldn't find currentMusicIndex
                // clear playlist cache and reload
                if (!isDefaultMusicSet && typeof currentMusicIndex === 'undefined' && musicId && !refreshPlaylistCount) {
                    // eslint-disable-next-line no-console
                    console.log('no currentMusicIndex: musicId: ', musicId, ' refreshPlaylistCount: ', refreshPlaylistCount);

                    clearPlaylistCache({
                        targetPlaylist,
                        musicId,
                    });

                    setTimeout(()=>{
                        refreshPlaylistCount = 1;
                        getAllPlaylists();
                    }, 500);
                    
                    return;
                }
                
                // after 1 time playlist refresh if still no currentMusicIndex
                if (typeof currentMusicIndex === 'undefined' && refreshPlaylistCount) {
                    // reset refresh playlist count
                    refreshPlaylistCount = 0;
                    // redirect to playable music
                    navigate('/', { replace: true });
                    return;
                }
                

                // set meta tag only we have find the music details
                const {
                    musicName = '',
                    cover = '',
                } = targetPlaylist.musics[currentMusicIndex];

                // TODO - these meta tag change is not that useful
                updateMetaTag({
                    tagName: 'og:title',
                    content: musicName,
                });

                updateMetaTag({
                    tagName: 'og:image',
                    content: cover,
                });
                

                changeMusic({
                    playlistId: currentPlaylistId,
                    musicId: currentMusicId,
                    index: currentMusicIndex
                });

                setDefaultMusicSet(true);
            }
        };

        if (isDefaultPlaylistsLoaded) {
            // redirect to home, if playlistId doesn't match
            // @ts-ignore
            if (playlistId && !playlists[playlistId]) {
                navigate('/', { replace: true });
                return;
            }

            // set default music or land from shared url. Only run once
            // on music route change
            if (playlistId && !musicId) {
                changeDefaultMusic();
                return;
            }
            
            if (currentMusic.musicId) {
                if (playlistId && currentMusic.musicId !== musicId) {
                    changeMusic({
                        playlistId,
                        musicId,
                    });
                }
            } else if (!isDefaultMusicSet) {
                // on mount - when user arrive via share link or new created song link
                // eslint-disable-next-line no-console
                console.log('refreshPlaylistCount: ', refreshPlaylistCount);
                changeDefaultMusic();
                // open music detail
                if (playlistId && musicId && !currentMusic.isPlayerOpen) {
                    setPlayerOpen(true);
                }
            }
        }
        
    }, [playlists, playlistId, musicId, isDefaultPlaylistsLoaded, isDefaultMusicSet, setDefaultMusicSet]);

    // fetch user's playlists
    useEffect(()=> {
        if (Object.keys(userPlaylists).length) {
            getPlaylistsMusics(userPlaylists);
        }
    }, [userPlaylists]);


    useEffect(() => {
        // close modal on home url
        if ((location.pathname === '/') && currentMusic.isPlayerOpen) {
            setPlayerOpen(false);
        }
    },[location]);

    // Refresh default playlist when there is item in createMusicQueue
    // the reducer will remove the queue item if new song added into the playlist
    // NOTE: the Router will unmount the component between routes
    useEffect(()=> {
        let refreshTimer: any;

        if (Array.isArray(createMusicQueue)) {
            if (createMusicQueue.length) {
                clearTimeout(refreshTimer);
                refreshTimer = setTimeout(()=> {
                    getPlaylist({ playlistId: DEFAULT_PLAYLIST_ID });
                }, REFRESH_PLAYLIST_TIME);
            } else {
                clearTimeout(refreshTimer);
            }
        }

        return  function cleanUp() {
            if (Array.isArray(createMusicQueue) && !createMusicQueue.length) {
                clearTimeout(refreshTimer);
            }
        };
    }, [createMusicQueue]);

    const isUsersPlaylistLoaded = loadPlaylistsStatus === SUCCESS;

    let currentPlaylistData = isDefaultPlaylistsLoaded 
        ? playlists[currentMusic.playlistId]
        : {};

    // always use default playlist before login
    if (!isAuthenticated) {
        currentPlaylistData = playlists[DEFAULT_PLAYLIST_ID || ''];
    }

    // user's playlist
    if (isAuthenticated && playlistId && !musicId && isUsersPlaylistLoaded) {
        currentPlaylistData = playlists[playlistId];
    }

    const currentPlaylistTitle = currentPlaylistData?.title || '';

    const CreateSection = (
        <div className="home__intro">
            <h2>Make Your Own Songx</h2>
            <div className="home__intro-butons-section">
                <CreateButton onClick={()=> setIsCreateDialogOpen(true)}/>
                {!deviceInfo.isDesktop && <ShareButton asTextButton />}
            </div>
            <Suspense>
                <ShareBar />
            </Suspense>
        </div>
    );

    const songlistTracedScrollBlock = (
        <SongListTrackedScroll 
            // @ts-ignore
            playlistData={currentPlaylistData}
            displayMode={playlistMode}
        />
    );

    const songlistSection = isDesktop
        ? (
            <div className="home__right-col">
                {songlistTracedScrollBlock}
            </div>
        )
        : songlistTracedScrollBlock;

    return (
        <Container 
            className="page-main-container home"
            maxWidth="lg"
        >
            {CreateSection}
            
            <MiniPlayer 
                // @ts-ignore
                isModalOpen={currentMusic.isPlayerOpen}
                setIsModalOpen={setPlayerOpen}
            />
            <Grid 
                container
                spacing={ isDesktop ? 2 : 0}
            >
                {isAuthenticated && (
                    <Grid 
                        className="home__left-col"
                        size={{ xs: 12 }}
                    >
                    
                        <PlaylistsScroller 
                            enableScroll={isMobile}
                            currentPlaylistData={currentPlaylistData} 
                        />
                    
                    </Grid>
                )}
                <Grid size={{ xs: 12 }}>
                    {isDefaultPlaylistsLoaded &&  (
                        <>
                            <PlaylistToggle 
                                className={`mb-1 ${isDesktop ? 'mt-1': 'mt-2'}`}
                                playlistTitle={currentPlaylistTitle}
                                onViewChange={setPlaylistMode}
                            />
                            { songlistSection }
                            <SlideDialog 
                                className={isDesktop ? 'slide-dialog--desktop' :  ''}
                                open={!!slideDialog.isOpen}
                                onClose={setSlideDialogOpen}
                                disable={isDesktop}
                            >
                                <AddSong />
                            </SlideDialog>
                        </>
                    )}
                </Grid>
            </Grid>

            <SlideDialog 
                className={`create-dialog ${isDesktop ? 'slide-dialog--desktop' :  ''}`}
                open={isCreateDialogOpen}
                onClose={({
                    isOpen
                })=>{setIsCreateDialogOpen(isOpen);}}
            >
                <CreatList />
            </SlideDialog>
        </Container>
    );
}

export default Home;
